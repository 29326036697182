import {
  ActionIcon,
  Affix,
  AppShell,
  Box,
  Card,
  Center,
  Stack,
  Table,
  Text,
  Timeline,
} from "@mantine/core";
import { Profile } from "./components/profile";
import { Actions } from "./components/actions";
import { IconRefresh } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Config, ThemeConfig } from "./config";
// import Websocket from "ws";

const getRewardName = (id: string) => {
  switch (id) {
    case "b8cb28b0-d1e1-4cee-8226-45a14c051666":
      return "gold medal";
    case "e546b6cc-476f-4662-8f8b-53c82006fa80":
      return "green verification";
    case "b1a53f8c-7f7f-47d3-9899-718346cc0f40":
      return "leaf star";
    case "e7671a26-788c-48bc-a904-cb6a25172915":
      return "blue verification";
    case "2a299bfb-acb7-4c0c-b31e-9f175d773b08":
      return "emerald badge";
    case "7f680894-6603-4cda-9fc4-b81cf4c93681":
      return "red medal";
    case "61a61196-8a8a-4b54-91ea-dcb65cf237f3":
      return "hero badge";
    default:
      return "mystery reward";
  }
};

export default function App() {
  let { authId } = useParams();
  const [flows, setFlows] = useState<any[]>([]);
  const [leaderboard, setLeaderboard] = useState<any[]>([]);
  const [timeline, setTimeline] = useState<any[]>([]);
  const [points, setPoints] = useState(0);
  const [experience, setExperience] = useState(0);
  const [rewards, setRewards] = useState<string[]>([]);
  const [timelineKey, setTimelineKey] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getLeaderboard();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    var conn = new WebSocket(
      `${Config.RewardsServiceEndpoint.replace("https://", "wss://").replace(
        "http://",
        "ws://"
      )}/v1/clients/${Config.ClientId}/timeline/${authId}/ws`
    );

    conn.onopen = function () {
      notifications.show({
        title: "Connection established",
        message: "You are connected to the timeline's live feed 🎉",
      });
    };

    conn.onclose = function (event) {
      notifications.show({
        title: "Connection closed",
        message: "Live feed connection closed, refresh the page 🤕",
        color: "red",
        autoClose: false,
      });
    };
    conn.onmessage = function (event) {
      var data = JSON.parse(event.data);

      setTimeline((timeline) => [data, ...timeline]);

      switch (data.type) {
        case "POINTS":
          if (data.operation === "CREDIT") {
            console.log("credit points", data.data.amount);
            setPoints((points) => points + data.data.amount);
          } else {
            setPoints((points) => points - data.data.amount);
          }
          break;
        case "EXPERIENCE":
          if (data.operation === "CREDIT") {
            console.log("credit exp", data.data.amount);
            setExperience((exp) => exp + data.data.amount);
          } else {
            setExperience((exp) => exp - data.data.amount);
          }
          break;
        case "REWARD":
          if (data.operation === "CREDIT") {
            console.log("credit reward", data.data.rewardId);
            setRewards((rewards) => [...rewards, data.data.rewardId]);
          } else {
            setRewards((rewards) =>
              rewards.filter((id) => id !== data.data.rewardId)
            );
          }
          break;
      }

      notifications.show({
        title: "New Reward! 🎉",
        message: "New transaction just occured",
      });
      getLeaderboard();
    };
  }, [authId]);

  const getLeaderboard = async () => {
    axios
      .get(
        `${Config.RewardsServiceEndpoint}/v1/clients/${Config.ClientId}/leaderboards/fdd85e5a-6890-49cb-883d-59e640753af0?features=POINTS,EXPERIENCE&size=10`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: Bearer,
          },
        }
      )
      .then((response) => {
        setLeaderboard(response.data.data);
      });
  };

  const getFlows = async () => {
    axios
      .get(
        `${Config.RulesEngineEndpoint}/v1/admin/clients/${Config.ClientId}/flows?flat=true`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: Bearer,
          },
        }
      )
      .then((response) => {
        setFlows(response.data.data);
        getTimeline();
      });
  };

  const getTimeline = async () => {
    axios
      .get(
        `${Config.RewardsServiceEndpoint}/v1/clients/${Config.ClientId}/timeline/${authId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: Bearer,
          },
        }
      )
      .then((response) => {
        var pts = 0;
        var exp = 0;
        var rw: string[] = [];

        var transactions = response.data.data.map((transaction: any) => {
          if (transaction.type === "EXPERIENCE") {
            exp += transaction.data.amount;
          } else if (transaction.type === "POINTS") {
            pts += transaction.data.amount;
          } else if (transaction.type === "REWARD") {
            rw.push(transaction.data.rewardId);
          }

          return transaction;
        });
        setTimeline(transactions);
        setPoints(pts);
        setExperience(exp);
        setRewards(rw);
      });
  };

  useEffect(() => {
    getFlows();
    getLeaderboard();
  }, [authId]);

  useEffect(() => {
    const timer = setInterval(() => setTimelineKey(Math.random()), 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <html>
      <body
        style={{
          backgroundImage: ThemeConfig.BackgroundImage
            ? `url(${ThemeConfig.BackgroundImage})`
            : undefined,
          // backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <Affix position={{ bottom: 30, right: 50 }}>
            <Text fz="lg" fw={400} c="dimmed">
              <Text span c="red" fw={700} inherit>
                FanX
              </Text>{" "}
              {ThemeConfig.AffixLabel}
            </Text>
          </Affix>
          <Affix position={{ left: 30, top: 30 }}>
            <ActionIcon
              color={ThemeConfig.PrimaryColor}
              onClick={() => {
                window.location.reload();
              }}
              variant="light"
              size="xl"
            >
              <IconRefresh />
            </ActionIcon>
          </Affix>
          <AppShell padding="md">
            <AppShell.Main>
              <Box>
                {/* mt={"4em"} */}
                <Stack mt={"5em"} gap="1em">
                  <Center w="100%">
                    <Actions authId={authId} />
                  </Center>
                  <Center w="100%">
                    <Profile
                      authId={authId}
                      flows={flows}
                      points={points}
                      experience={experience}
                      rewards={rewards}
                      timeline={timeline}
                    />
                  </Center>
                  <Center w="100%" mt="1em" mb="1em">
                    <Card w={500} withBorder p="xl" radius="md">
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>User</Table.Th>
                            <Table.Th>Points</Table.Th>
                            <Table.Th>Experience</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {leaderboard.map((user) => {
                            var me = user.authId.toString() === authId;
                            return (
                              <Table.Tr
                                bg={me ? "blue.0" : ""}
                                fw={me ? 600 : undefined}
                                key={user.authId}
                              >
                                <Table.Td>#{user.authId.toString()}</Table.Td>
                                <Table.Td>{user.features.POINTS}</Table.Td>
                                <Table.Td>{user.features.EXPERIENCE}</Table.Td>
                              </Table.Tr>
                            );
                          })}
                        </Table.Tbody>
                      </Table>
                    </Card>
                  </Center>
                  <Center w="100%" mt="2em" mb="5em">
                    <Timeline
                      key={`${timelineKey}`}
                      reverseActive
                      lineWidth={5}
                      bulletSize={22}
                    >
                      {timeline.map((transaction, index) => {
                        return (
                          <Timeline.Item key={`timeline-element-${index}`}>
                            <Card w={400} withBorder shadow="md">
                              <Text fw={600} mb="sm">
                                {flows.find(
                                  (v) => v.id === transaction.data?.flowId
                                )?.name ?? ""}
                              </Text>
                              <Text c="dimmed" size="sm">
                                You&apos;ve just earned{" "}
                                <Text span c="blue" fw={550} inherit>
                                  {transaction.data.rewardId
                                    ? getRewardName(transaction.data.rewardId)
                                    : transaction.data.amount.toLocaleString()}{" "}
                                  {transaction.type === "EXPERIENCE"
                                    ? "exp"
                                    : transaction.type === "POINTS"
                                    ? "points"
                                    : ""}
                                </Text>{" "}
                                for completing the challenge
                              </Text>
                              <Text size="xs" c="teal" mt={4}>
                                {moment(transaction.createdAt).fromNow()}
                              </Text>
                            </Card>
                          </Timeline.Item>
                        );
                      })}
                    </Timeline>
                  </Center>
                </Stack>
              </Box>
            </AppShell.Main>
          </AppShell>
        </div>
      </body>
    </html>
  );
}
