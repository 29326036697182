export const Config = {
  ClientId: "INCROWD",
  RulesEngineEndpoint: "https://rules-engine-test.incrowdsports.com",
  RewardsServiceEndpoint: "https://rewards-service-stage.incrowdsports.com",
};

export const ThemeConfig = {
  MainLabel: "InCrowd Demo",
  ClientLabel: "InCrowd",
  BackgroundImage: null, //"https://www.brentfordfc.com/assets/1.0.34279/193a80ba51a54cd4b3fe.png"
  LogoUrl:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2rkMFZXnD-W4fhnvvpjxV1JKQvr2IXGn65Dp5wQNoYg&s",
  PrimaryColor: "blue",
  AffixLabel: "Internal Demo",
};
