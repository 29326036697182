import { Button, Input, Modal, Stack } from "@mantine/core";
import { useState } from "react";
import { ThemeConfig } from "../../config";

export function ArticlesModal({
  opened,
  onClose,
  onSubmit,
}: {
  opened: boolean;
  onClose: any;
  onSubmit: any;
}) {
  const [articleid, setArticleId] = useState("");
  return (
    <Modal
      withCloseButton={false}
      yOffset="25vh"
      opened={opened}
      onClose={onClose}
    >
      <Stack>
        <Input.Wrapper label="Article Id">
          <Input
            value={articleid}
            onChange={(event) => setArticleId(event.target.value)}
          />
        </Input.Wrapper>
        <Button
          color={ThemeConfig.PrimaryColor}
          mt="1em"
          fullWidth
          onClick={() => {
            onSubmit({
              sourceSystem: "articles-management-system",
              sourceSystemId: "article_opened",
              priority: 0,
              authId: -1,
              data: {
                serviceName: "Articles",
                articleId: articleid,
              },
            });
            setArticleId("");
          }}
        >
          Submit
        </Button>
      </Stack>
    </Modal>
  );
}
