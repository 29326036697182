import {
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  useMantineTheme,
} from "@mantine/core";
import {
  IconReport,
  IconCoin,
  IconBook,
  IconList,
  IconForms,
  IconClick,
  IconHeartbeat,
  IconPackage,
  IconShare,
  IconCreditCard,
} from "@tabler/icons-react";
import classes from "./actions.module.css";
import { useState } from "react";
import { ArticlesModal } from "../modals/articles";
import { PollsModal } from "../modals/polls";
import { FormsModal } from "../modals/forms";
import axios from "axios";
import { Config } from "../../config";

const mockdata = [
  { title: "Articles", icon: IconBook, color: "violet", modal: "articles" },
  { title: "Polls", icon: IconList, color: "indigo", modal: "polls" },
  { title: "Forms", icon: IconForms, color: "blue", modal: "forms" },
  { title: "Socials", icon: IconShare, color: "cyan", disabled: true },
  { title: "Tracking", icon: IconClick, color: "teal", disabled: true },
  { title: "Activity", icon: IconHeartbeat, color: "cyan", disabled: true },
  { title: "Aggregations", icon: IconReport, color: "pink", disabled: true },
  { title: "Payments", icon: IconCreditCard, color: "red", disabled: true },
  { title: "Integrations", icon: IconPackage, color: "grape", disabled: true },
];

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function Actions({ authId }: { authId: any }) {
  const theme = useMantineTheme();
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const publishEvent = async (event: any) => {
    event.id = uuidv4();
    event.authId = parseInt(authId, 10);
    event.clientId = Config.ClientId;

    event.data.leaderboards = {
      keys: ["fdd85e5a-6890-49cb-883d-59e640753af0"],
      dimensions: ["GLOBAL"],
    };

    axios
      .post(
        `${Config.RulesEngineEndpoint}/v1/admin/clients/${Config.ClientId}/events`,
        event,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: Bearer,
          },
        }
      )
      .then((response) => {
        onClose();
      });
  };

  const onClose = () => {
    setActiveModal(null);
  };

  const items = mockdata.map((item) => (
    <UnstyledButton
      style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}
      disabled={item.disabled}
      opacity={item.disabled ? 0.5 : 1}
      key={item.title}
      className={classes.item}
      onClick={() => {
        if (!item.disabled) {
          setActiveModal(item.modal || null);
        }
      }}
    >
      <item.icon color={theme.colors[item.color][6]} size="2rem" />
      <Text size="xs" mt={7}>
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <Card w="500px" withBorder radius="md" className={classes.card} pb="lg">
      <ArticlesModal
        opened={activeModal === "articles"}
        onClose={onClose}
        onSubmit={(event: any) => {
          publishEvent(event);
        }}
      />
      <PollsModal
        opened={activeModal === "polls"}
        onClose={onClose}
        onSubmit={(event: any) => {
          publishEvent(event);
        }}
      />
      <FormsModal
        opened={activeModal === "forms"}
        onClose={onClose}
        onSubmit={(event: any) => {
          publishEvent(event);
        }}
      />
      <Group justify="space-between" mb="md">
        <Text className={classes.title}>Integrations</Text>
        <Anchor size="xs" c="dimmed" style={{ lineHeight: 0.5 }}>
          Plug & Play
        </Anchor>
      </Group>
      <SimpleGrid cols={3}>{items}</SimpleGrid>
    </Card>
  );
}
