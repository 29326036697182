import {
  Button,
  Card,
  Center,
  Input,
  Modal,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { ThemeConfig } from "../../config";

export function PollsModal({
  opened,
  onClose,
  onSubmit,
}: {
  opened: boolean;
  onClose: any;
  onSubmit: any;
}) {
  const theme = useMantineTheme();
  const [pollId, setPollId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  return (
    <Modal
      withCloseButton={false}
      yOffset="25vh"
      opened={opened}
      onClose={onClose}
    >
      <Stack>
        <Input.Wrapper label="Poll Id">
          <Input
            value={pollId}
            onChange={(event) => setPollId(event.target.value)}
          />
        </Input.Wrapper>
        <Stack gap={5}>
          {["first", "second", "third", "fourth"].map((value) => {
            return (
              <Card
                key={`option-id-${value}`}
                onClick={() => {
                  setSelectedOption(value);
                }}
                style={{
                  cursor: "pointer",
                  borderColor:
                    selectedOption === value
                      ? theme.colors.green[5]
                      : theme.colors.gray[3],
                  backgroundColor:
                    selectedOption === value ? theme.colors.green[0] : "white",
                  borderWidth: "2px",
                }}
                withBorder
              >
                <Center>{value}</Center>
              </Card>
            );
          })}
        </Stack>
        <Button
          color={ThemeConfig.PrimaryColor}
          mt="1em"
          fullWidth
          onClick={() => {
            onSubmit({
              sourceSystem: "polls-service",
              sourceSystemId: "poll_submitted",
              priority: 0,
              authId: -1,
              data: {
                serviceName: "Polls",
                pollId: pollId,
                optionId: selectedOption,
              },
            });
            setPollId("");
            setSelectedOption("");
          }}
        >
          Submit
        </Button>
      </Stack>
    </Modal>
  );
}
