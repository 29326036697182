import {
  ActionIcon,
  Affix,
  AppShell,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  NumberInput,
  PinInput,
  Stack,
  Text,
  Timeline,
} from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Config, ThemeConfig } from "./config";

export default function Selector() {
  const navigate = useNavigate();
  const [authId, setAuthId] = useState(0);

  useEffect(() => {
    setAuthId(Math.floor(Math.random() * (99999 - 10000 + 1) + 10000));
  }, []);

  return (
    <div>
      <AppShell padding="md">
        <AppShell.Main>
          <Center w="100%" h="90vh">
            <Stack>
              <Center>
                {/* <Text fz={30} fw={700} c={ThemeConfig.PrimaryColor}>
                  {ThemeConfig.MainLabel}
                </Text> */}
                <Avatar mb="sm" mt="sm" src={ThemeConfig.LogoUrl} size={150} />
              </Center>
              <Group gap={"md"}>
                <PinInput
                  gap={6}
                  size={"xl"}
                  length={5}
                  type="number"
                  value={authId.toString()}
                  onChange={(value) => {
                    var n = parseInt(value);
                    if (!isNaN(n)) {
                      setAuthId(n);
                    }
                  }}
                />
                <ActionIcon
                  variant="light"
                  color={ThemeConfig.PrimaryColor}
                  onClick={() => {
                    navigate(`/profile/${authId.toString()}`);
                  }}
                  size={60}
                >
                  <IconArrowRight />
                </ActionIcon>
              </Group>
            </Stack>
          </Center>
        </AppShell.Main>
      </AppShell>
    </div>
  );
}
