import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Selector from "./Selector";
import { ThemeConfig } from "./config";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Selector />,
  },
  {
    path: "/profile/:authId",
    element: <App />,
  },
]);

const theme = createTheme({
  primaryColor: ThemeConfig.PrimaryColor,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MantineProvider theme={theme}>
    <Notifications position="top-right" zIndex={1000} />
    <RouterProvider router={router} />
  </MantineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
