import {
  Text,
  Card,
  RingProgress,
  Group,
  useMantineTheme,
  Avatar,
  Stack,
  DEFAULT_THEME,
  Badge,
} from "@mantine/core";
import classes from "./profile.module.css";
import { FlowsModal } from "../modals/flows";
import { useEffect, useState } from "react";
import { Config, ThemeConfig } from "../../config";
import { RadarChart, Sparkline } from "@mantine/charts";
import "@mantine/charts/styles.css";

export function Profile({
  authId,
  flows,
  points,
  experience,
  rewards,
  timeline,
}: {
  authId: any;
  flows: any[];
  points: number;
  experience: number;
  rewards: string[];
  timeline: any[];
}) {
  const [flowsModal, setFlowsModal] = useState(false);
  const [lineData, setLineData] = useState(new Array(100).fill(20));

  useEffect(() => {
    var i = 0;

    const intervalId = setInterval(() => {
      setLineData((prevLineData) => {
        i += 1;
        const lastValue = prevLineData[prevLineData.length - 1];

        var newValue = lastValue;
        if (i % 5 === 0) {
          newValue = newValue * Math.random() + 2;
        }

        newValue =
          newValue + newValue * 0.07 * (Math.round(Math.random()) * 2 - 1);

        const newLineData = [...prevLineData.slice(1), newValue];
        return newLineData;
      });
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card w={500} withBorder p="xl" radius="md" className={classes.card}>
      {/* {flowsModal && (
        <FlowsModal flows={flows} onClose={() => setFlowsModal(false)} />
      )} */}
      <div className={classes.inner}>
        <div style={{ width: "100%" }}>
          <Text fz="xl" className={classes.label}>
            {`${ThemeConfig.ClientLabel} User: `}
            <Text
              style={{ cursor: "pointer" }}
              span
              c={ThemeConfig.PrimaryColor}
              fw={600}
              inherit
            >
              #{authId}
            </Text>
          </Text>
          <Group mt={10} justify="flex-start" gap={5}>
            <Badge variant="light" color="blue" radius="sm">
              Sports
            </Badge>
            <Badge variant="light" color="blue" radius="sm">
              Lionel Messi
            </Badge>
            <Badge variant="light" color="gray" radius="sm">
              Travel
            </Badge>
          </Group>
          <Group mt={0} gap="xl">
            <div>
              <Text className={classes.lead} mt={30}>
                {points.toLocaleString()}
              </Text>
              <Text fz="xs" c="dimmed">
                Points
              </Text>
            </div>
            <div>
              <Text className={classes.lead} mt={30}>
                {experience.toLocaleString()}
              </Text>
              <Text fz="xs" c="dimmed">
                Experience
              </Text>
            </div>
            {/* <div
              style={{ cursor: "pointer" }}
              onClick={() => setFlowsModal(true)}
            >
              <Text className={classes.lead} mt={30}>
                {flows.reduce((t, flow) => {
                  return t + flow.completed ? 1 : 0;
                }, 0)}
                /{flows.length}
              </Text>
            </div> */}
          </Group>
          <Group mt={20} gap={5}>
            {rewards.includes("b8cb28b0-d1e1-4cee-8226-45a14c051666") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/f697a509-7b1d-4685-9f52-e330c4dbf17e.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("e546b6cc-476f-4662-8f8b-53c82006fa80") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/499b03c5-3d4e-4459-ac52-0f7ba125618e.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("b1a53f8c-7f7f-47d3-9899-718346cc0f40") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/6b9ed252-c4b6-4302-b77a-04031b90537a.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("e7671a26-788c-48bc-a904-cb6a25172915") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/c998e689-cc77-4b78-941f-b9fb982a734c.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("2a299bfb-acb7-4c0c-b31e-9f175d773b08") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/899549bf-e133-49b7-bb17-5d7260694730.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("7f680894-6603-4cda-9fc4-b81cf4c93681") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/580fcb07-7502-4c0e-b538-1f2db3c2ff15.png"
                radius="xl"
                size={25}
              />
            )}
            {rewards.includes("61a61196-8a8a-4b54-91ea-dcb65cf237f3") && (
              <Avatar
                src="https://media-cdn.incrowdsports.com/8f1b2191-5d4f-4b16-858b-b3e4bb93dd5a.png"
                radius="xl"
                size={25}
              />
            )}
          </Group>
        </div>

        <div className={classes.ring}>
          <Avatar mt="sm" src={ThemeConfig.LogoUrl} size={150} />
        </div>
      </div>
      <Stack mt="1em" gap={2}>
        {/* <Text fz="lg">Insights</Text> */}
        <Group justify="space-between">
          <Text fz="sm">
            <b>Gender</b>: Male
          </Text>
          <Text fz="sm">
            <b>Age</b>: 18-25
          </Text>
          <Text fz="sm">
            <b>Location</b>: UK
          </Text>
          <Text fz="sm">
            <b>Club</b>: InCrowd
          </Text>
        </Group>
        <Group justify="space-between">
          <Text fz="sm">
            <b>Online</b>:{" "}
            <span
              style={{ fontWeight: 600, color: DEFAULT_THEME.colors.green[5] }}
            >
              now
            </span>
          </Text>
          <Text fz="sm">
            <b>Lifetime Value</b>: 813.25£
          </Text>
          <Text fz="sm">
            <b>Product</b>: iOS App
          </Text>
        </Group>
        <Stack gap={0} mt={5}>
          <RadarChart
            h={300}
            w="100%"
            data={[
              {
                product: "Spending",
                january: 120,
                february: 100,
              },
              {
                product: "Engagement",
                january: 110,
                february: 90,
              },
              {
                product: "Loyalty",
                january: 90,
                february: 70,
              },
              {
                product: "Experience",
                january: 99,
                february: 80 + timeline.length,
              },
              {
                product: "Data",
                january: 85,
                february: 10 + timeline.length * 3,
              },
              {
                product: "Activity",
                january: 65,
                february: 20 + timeline.length * 4,
              },
            ]}
            dataKey="product"
            // withPolarRadiusAxis
            series={[
              { name: "january", color: "lime.4", opacity: 0.1 },
              { name: "february", color: "cyan.4", opacity: 0.1 },
            ]}
          />
          <Group w="100%">
            <Sparkline
              trendColors={{
                positive: "red.6",
                negative: "teal.6",
                // neutral: "teal.6",
              }}
              fillOpacity={0.2}
              w={"100%"}
              h={100}
              data={lineData}
              curveType="bump"
              color="cyan"
              strokeWidth={2}
            />
          </Group>
          <Text ta="center" fw={500} fz="sm" c="dimmed">
            Churn Risk
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
}
