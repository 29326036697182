import {
  Button,
  Card,
  Center,
  Input,
  Modal,
  Stack,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { ThemeConfig } from "../../config";

export function FormsModal({
  opened,
  onClose,
  onSubmit,
}: {
  opened: boolean;
  onClose: any;
  onSubmit: any;
}) {
  const theme = useMantineTheme();
  const [formId, setFormId] = useState("");
  const [question1, setQueestion1] = useState("");
  const [question2, setQueestion2] = useState("");
  const [question3, setQueestion3] = useState("");
  return (
    <Modal
      withCloseButton={false}
      yOffset="25vh"
      opened={opened}
      onClose={onClose}
    >
      <Stack>
        <Input.Wrapper label="Form Id">
          <Input
            value={formId}
            onChange={(event) => setFormId(event.target.value)}
          />
        </Input.Wrapper>
        <Stack gap={5}>
          <Textarea
            label="Question #1"
            value={question1}
            onChange={(event) => setQueestion1(event.target.value)}
          />
          <Textarea
            label="Question #2"
            value={question2}
            onChange={(event) => setQueestion2(event.target.value)}
          />
          <Textarea
            label="Question #3"
            value={question3}
            onChange={(event) => setQueestion3(event.target.value)}
          />
        </Stack>
        <Button
          color={ThemeConfig.PrimaryColor}
          mt="1em"
          fullWidth
          onClick={() => {
            onSubmit({
              sourceSystem: "forms-service",
              sourceSystemId: "form_submitted",
              priority: 0,
              authId: -1,
              data: {
                serviceName: "Forms",
                formId: formId,
                firstQuestion: question1,
                secondQuestion: question2,
                thirdQuestion: question3,
              },
            });
            setFormId("");
            setQueestion1("");
            setQueestion2("");
            setQueestion3("");
          }}
        >
          Submit
        </Button>
      </Stack>
    </Modal>
  );
}
